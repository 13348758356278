import { Center } from '@components/common/Center'
import { LoadingSpinner } from '@components/common/LoadingSpinner'
import React from 'react'

const SplashScreen: React.FC = () => {
    return (
        <Center>
            <LoadingSpinner />
        </Center>
    )
}

export default SplashScreen
