import classNames from 'classnames'
import React from 'react'

export const LoadingSpinner: React.FC<{
    size?: 'sm' | 'lg' | 'xl'
    className?: string
}> = ({ size = 'xl', className: customClassName }) => {
    const className = classNames(
        'animate-spin',
        size === 'sm' ? 'h-5 w-5' : size === 'lg' ? 'h-7 w-7' : 'h-10 w-10',
        customClassName,
        (!customClassName || !customClassName.includes('text-')) && 'text-boost2',
    )

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
    )
}
