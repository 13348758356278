import 'tailwindcss/tailwind.css'
import { useRouter } from 'next/router'
import { AuthProvider } from '../contexts/auth'
import { SurveyProvider } from '../contexts/survey'
import FacebookPixel from '../components/common/FacebookPixel'
import { appWithTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'
import '../services/client/firebaseClient'
import { Roboto_Flex } from 'next/font/google'
import Head from 'next/head'
import * as amplitude from '@amplitude/marketing-analytics-browser'
import { StatsigProvider } from 'statsig-react'
import { isDev, isPreview } from '@utils/checkEnv'
import { useEffect } from 'react'
import SplashScreen from '@components/common/SplashScreen'
import { useTranslation } from 'i18n'
import Hotjar from '@components/common/Hotjar'

amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, undefined, {
    appVersion: '2.26.0',
})

const robotoFlex = Roboto_Flex({ subsets: ['latin'], variable: '--font-roboto-flex' })

const Fastic = ({ Component, pageProps, ...props }) => {
    const router = useRouter()
    const { i18n } = useTranslation()

    useEffect(() => {
        const queryParams = router.query
        if (!isEmpty(queryParams) && !window.sessionStorage.getItem('queryParams')) {
            window.sessionStorage.setItem('queryParams', JSON.stringify(queryParams))
        }
    }, [])

    const err = (props as any).err

    return (
        <FacebookPixel>
            <Hotjar>
                <AuthProvider>
                    <StatsigProvider
                        sdkKey="client-Q9gSicBXB2cixElOevyP9GoQQ5LJtMAxABZpGW0hZya"
                        user={{
                            locale: i18n.language,
                        }}
                        options={{
                            environment: { tier: isPreview() ? 'staging' : isDev() ? 'development' : 'production' },
                        }}
                        waitForInitialization={true}
                        initializingComponent={<SplashScreen />}
                    >
                        <SurveyProvider>
                            <Head>
                                <meta charSet="UTF-8" />
                                <meta
                                    name="viewport"
                                    content="width=device-width,user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1"
                                />
                                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                            </Head>
                            <main className={`${robotoFlex.variable} font-roboto`}>
                                <Component {...pageProps} err={err} />
                                <div id="portals" />
                            </main>
                        </SurveyProvider>
                    </StatsigProvider>
                </AuthProvider>
            </Hotjar>
        </FacebookPixel>
    )
}

export default appWithTranslation(Fastic)
