import React, { useState, createContext, useEffect, useContext } from 'react'
import * as Sentry from '@sentry/nextjs'
import { User as FbUser, onIdTokenChanged, signInAnonymously as fbSignInAnonymously } from 'firebase/auth'
import { fbAuth } from '@services/client/firebaseClient'

export interface User {
    id: string
    email: string
    name: string
    photoUrl?: string
}

export interface Auth {
    initialized: boolean
    user?: User
}

const authContext = createContext<{ auth: Auth; refetchUser: () => Promise<void> }>(null)

export const AuthProvider: React.FC<React.PropsWithChildren> = props => {
    const [auth, setAuth] = useState<Auth>({ initialized: false })

    const onFirebaseAuthStateChange = async (user: FbUser | null) => {
        await getAndSetUser(user)
    }

    const getAndSetUser = async (firebaseUser: FbUser) => {
        if (firebaseUser) {
            const { uid, email, displayName, photoURL } = firebaseUser
            setAuth({
                initialized: true,
                user: {
                    id: uid,
                    name: displayName,
                    email,
                    photoUrl: photoURL,
                },
            })
        } else {
            setAuth({ initialized: true })
        }
    }

    // use it after sign up to update email and name
    const refetchUser = async () => {
        const firebaseCurrentUser = await fbAuth.currentUser
        await getAndSetUser(firebaseCurrentUser)
    }

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(fbAuth, onFirebaseAuthStateChange)
        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (auth.user?.id) {
            Sentry.setUser({ id: auth.user.id })
        }
    }, [auth.user?.id])

    return <authContext.Provider value={{ auth, refetchUser }}>{props.children}</authContext.Provider>
}

export const signInAnonymously = async (): Promise<void> => {
    await fbSignInAnonymously(fbAuth)
}

export const useAuth = (): [User, boolean] => {
    const { auth } = useContext(authContext)
    return [auth.user, auth.initialized]
}

export const useUser = (): User => {
    const { auth } = useContext(authContext)
    return auth.user
}

export const useRefetechUser = () => {
    const { refetchUser } = useContext(authContext)
    return refetchUser
}
