import { analytics } from './firebaseClient'
import * as amplitude from '@amplitude/marketing-analytics-browser'
import { setUserId as setUserIdGA } from 'firebase/analytics'
import { setUserId as setUserId, identify, track, Identify, flush } from '@amplitude//marketing-analytics-browser'
import { moengage } from './moengage'
import { hotjar } from 'react-hotjar'
import { isDev } from '@utils/checkEnv'

const setAmplitudeUserId = (userId: string): void => {
    setUserId(userId)
    setUserIdGA(analytics, userId)
    window.fbq('init', process.env.NEXT_PUBLIC_FB_PIXEL_ID, { external_id: userId })
    moengage.init(userId)
}

const setAmplitudeUserProperty = (property: string, value: amplitude.Types.ValidPropertyType): void => {
    identify(new Identify().set(property, value))
    moengage.setMoengageUserAttribute(property, value)
}

const setAmplitudeUserProperties = (
    userId: string,
    properties: { [key: string]: amplitude.Types.ValidPropertyType },
): void => {
    let update = new Identify()
    for (const key in properties) {
        update = update.set(key, properties[key])
        moengage.setMoengageUserAttribute(key, properties[key])
    }
    identify(update)
    if (!isDev) {
        hotjar.identify(userId, properties)
    }
}

const trackAmplitudeEvent = (name: string, properties?: { [key: string]: unknown }): void => {
    track(name, properties)
    if (!isDev) {
        hotjar.event(name)
    }
}

const flushAmplitude = (): void => {
    flush()
}

export { setAmplitudeUserId, setAmplitudeUserProperty, setAmplitudeUserProperties, trackAmplitudeEvent, flushAmplitude }
