import { useEffect } from 'react'
import { isDev } from '@utils/checkEnv'
import { hotjar } from 'react-hotjar'

const Hotjar: React.FC<React.PropsWithChildren<unknown>> = props => {
    useEffect(() => {
        if (!isDev()) {
            hotjar.initialize(2570788, 6, isDev())
        }
    }, [])

    return <>{props.children}</>
}

export default Hotjar
