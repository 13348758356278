import { isDev } from '@utils/checkEnv'
import { MoEngage } from 'types/moengage'

class MoEngageWrapper {
    _moengage: MoEngage | undefined
    _queuedEvents: { name: string; properties?: Record<string, any> }[] = []
    _queuedAttribute: { key: string; value: any }[] = []

    attributeKeyMapping = {
        currency: 'currencyCode',
        gender: 'gender',
        locale: 'locale',
        timezone: 'timeZone',
        utc_offset: 'timeZoneOffset',
        height: 'height',
        weight: 'weight',
        target_weight: 'targetWeight',
    }

    init = (uid: string): void => {
        this._moengage = window.moe({
            app_id: 'HFO36GJH65I32X9S3FK7ZCDJ',
            debug_logs: isDev() ? 1 : 0,
            cluster: 'DC_2',
            disable_onsite: true,
        })
        this._moengage.add_unique_user_id(uid)

        if (this._queuedEvents.length > 0) {
            for (const event of this._queuedEvents) {
                this.trackEvent(event.name, event.properties)
            }
            this._queuedEvents = []
        }
        if (this._queuedAttribute.length > 0) {
            for (const attr of this._queuedAttribute) {
                this.setMoengageUserAttribute(attr.key, attr.value)
            }
            this._queuedAttribute = []
        }
    }

    trackEvent = (name: string, properties?: Record<string, any>): void => {
        if (!this._moengage) {
            this._queuedEvents.push({ name, properties })
            return
        }
        this._moengage.track_event(name, properties)
    }

    setMoengageUserAttribute = (key: string, value: any): void => {
        if (!this._moengage) {
            this._queuedAttribute.push({ key, value })
            return
        }

        if (key === 'email') {
            this._moengage.add_email(value)
        } else if (key === 'age') {
            this._moengage.add_birthday(new Date(new Date().getFullYear() - value, 0, 1))
        } else if (key === 'measurement_system') {
            this._moengage.add_user_attribute('units.length', value === 'metric' ? 'cm' : 'inch')
            this._moengage.add_user_attribute('units.volume', value === 'metric' ? 'L' : 'fl oz')
            this._moengage.add_user_attribute('units.weight', value === 'metric' ? 'kg' : 'lbs')
        } else if (this.attributeKeyMapping[key]) {
            this._moengage.add_user_attribute(this.attributeKeyMapping[key], value)
            if (key === 'gender') {
                this._moengage.add_gender(value === 'male' ? 'M' : 'F')
            }
        }
    }
}

export const moengage: MoEngageWrapper = new MoEngageWrapper()
